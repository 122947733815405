/// <reference path="../../shared_controllers/socketService.ts" />
/// <reference path="../../shared_controllers/util.ts" />
/// <reference path="../../app.ts" />
/// <reference path="../../shared_controllers/common_controllers.ts" />
/// <reference path="../../services/generic.services.ts" />
/// <reference path="../../services/workflows.services.ts" />

class workflowinvokerCtrl {
    static $inject: Array<string> = ['$scope', '$location', '$routeParams', '$compile', 'socketService', '$timeout', 'toastr'];
    loading: boolean = false;
    genericServices: GenericServiceClass;
    workflowServices: WorkflowsServiceClass;
    userServices: UsersServiceClass
    id: string;
    token: string;
    model: any;
    workflow: any;
    endpoint_id: string;
    instance_id: string;
    formid: string;
    focuson: string;
    focused: string = '';
    toastrObj: any;
    constructor(
        public $scope,
        public $location,
        public $routeParams,
        public $compile,
        public socketService: socketServiceFactory,
        public $,
        public toastr
    ) {
        
        this.toastrObj = toastr;
        this.id = this.$routeParams.id;
        this.token = this.$routeParams.token;
        this.genericServices = new GenericServiceClass(this.socketService.namespace.socket, this.socketService.namespace)
        this.workflowServices = new WorkflowsServiceClass(this.socketService.namespace.socket, this.socketService.namespace)
        this.userServices = new UsersServiceClass(this.socketService.namespace.socket, this.socketService.namespace)

        if (this.token !== null && this.token !== undefined) {
            //debugger;
            //this.ipCookie('jwt', user.token, {});
            this.userServices.settoken(this.token);
            this.userServices.settoken(this.token);
            this.userServices.settoken(this.token);
            this.userServices.settoken(this.token);
            //$scope.$broadcast('signin', this.user);
        }


        socketService.onSignedin(() => {
            //debugger;
            this.loaddata(this.id);
        });
    }
    goback() {
        this.$location.path("/flows");
    }

    isDisabled(param) {
        if (!param.readonly || param.readonly == '') {
            return false;
        }
        return param.readonly;
    }

    processform() {
        if (!this.model.payload) { this.model.payload = {}; }
        var keys = Object.keys(this.model.payload);

        var firstInput: any = null;
        // save as result object, for use in workflow
        keys.forEach(name => {
            var target = this.model.form.find((ele) => { return ele.name == name; })
            if (target) {
                target.value = this.model.payload[name];
                if (!firstInput) { firstInput = name; }
            }
        });
        if (!firstInput && this.model.form) {
            this.model.form.forEach(element => {
                if (!firstInput) { firstInput = element.name; }
            });
        }
        if (this.model.defaults) {
            this.model.form.forEach(element => {
                if (this.model.defaults[element.name]) {
                    element.options = [];
                    // element.options = this.model.defaults[element.name];
                    // element.options.forEach(item => {
                    //     if(!item.id) {
                    //         if(item._id) { item.id = item._id; }
                    //     }
                    // });
                    var thedefault = this.model.defaults[element.name];
                    if (Array.isArray(thedefault)) {
                        this.model.defaults[element.name].forEach(item => {
                            if (item.id) {
                                element.options.push({ id: item.id, name: item.name })
                            } else if (item._id) {
                                element.options.push({ id: item._id, name: item.name })
                            }
                        });
                    } else {
                        element.value = thedefault;
                    }
                }
            });
        }

        // ensure elements are visible
        if (!this.$scope.$$phase) { this.$scope.$apply(); }
        // now set focused element
        if (this.focused != '') {
            this.focuson = this.focused;
        } else if (firstInput) {
            this.focuson = firstInput;
        } else {
            this.focuson = 'backbutton';
        }
        if (!this.$scope.$$phase) { this.$scope.$apply(); }
    }
    async loadform(id) {
        if (this.formid) {
            if (this.model.form && id == this.formid) {
                this.processform();
                return;
            }
        }
        if (id == '' && this.model.form) {
            this.model.form = [];
            this.processform();
            return;
        }
        this.loading = true;
        var query = "_id eq '" + id + "'";
        try {
            var items = await this.genericServices.get(query, 1, null, null, null, 'workflows');
            this.formid = id;
            this.loading = false;
            if (items.length == 0) {
                // handleError(this.$scope, { error: { message: 'No such form was found ' + id } });
                this.toastrObj.error(GetErrorMessage({ error: { message: 'This form not exist anymore'} }));
                this.$location.path("/flow_tasks")
                return;
            }

            if (!this.model.form) {
                this.model.form = items[0].form;
            }
            this.processform();

        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));
        }
    }
    async loaddata(id) {
        this.loading = true;
        var query = "_id eq '" + id + "'";
        try {
            var items = await this.genericServices.get(query, 1, null, null, null, 'workflows');
            if (items.length == 0) {
                this.loading = false;
                // handleError(this.$scope, { error: { message: 'No such instance found ' + id } });
                this.toastrObj.error(GetErrorMessage({ error: { message: 'No such instance found ' + id } }));
                return;
            }
            if (items[0]._type == 'endpoint' || items[0]._type == 'wff') {
                this.workflow = items[0];
                this.endpoint_id = items[0]._id;
                this.submitworkflowinstance(null);
            }
            else {
                this.instance_id = id;
                this.model = items[0];
                if (!this.model._parent) {
                    this.loading = false;
                    // handleError(this.$scope, { error: { message: 'instance is missing _parent property!' } });
                    this.toastrObj.error(GetErrorMessage({ error: { message: 'instance is missing _parent property!' } }));
                    return;
                }
                this.endpoint_id = this.model._parent;
                if (this.model.state == 'new') {
                    this.submitworkflowinstance(this.model);
                    return;
                }
                var data = this.model;
                this.loadform(data.formid);
                this.loading = false;
            }
            if (!this.$scope.$$phase) { this.$scope.$apply(); }

        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));
        }
    }

    async submitworkflowinstance(model) {
        var endpoint_id = this.endpoint_id;
        if (model) {
            model = JSON.parse(angular.toJson(model));
        }

        // save as result object, for use in workflow
        if (model && model.form) {
            if (!model.payload) { model.payload = {}; }
            model.form.forEach(element => {
                //condition for table 
                if (element.type == "form" || element.type == "table") {
                    model.payload[element.name] = [];
                    element.forms.forEach(FormItem => {
                        var formData = {};
                        FormItem.form.forEach(FormElement => {


                            if (FormElement.name) {
                                formData[FormElement.name] = FormElement.value;
                            } else {
                                formData[FormElement.label] = FormElement.value;
                            }
                        });

                        model.payload[element.name].push(formData);

                    })
                } else {
                    if (element.name) {
                        model.payload[element.name] = element.value;
                    } else {
                        model.payload[element.label] = element.value;
                    }
                }

            });
        }
        clearError(this.$scope);
        if (this.model && this.model.state != 'userinput' && this.model.state != 'new') {
            this.goback();
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
            return;
        }
        //var $focused = $();

        var $focused = angular.element(document.querySelector(':focus'));
        if ($focused.length == 1) {
            this.focused = $focused[0].id;
        }
        this.focuson = '';
        if (!this.$scope.$$phase) { this.$scope.$apply(); }
        this.loading = true;
        try {
            var data = await this.workflowServices.invoke(endpoint_id, model);
            this.loading = false;
            if (this.instance_id != data._id) {
                if (data.state == 'userinput' || data.state == 'pending') {
                    this.$location.path("/workflowinvoker/" + data._id);
                    if (!this.$scope.$$phase) { this.$scope.$apply(); }
                    return;
                }
            }
            this.model = data;

            if (!this.$scope.$$phase) { this.$scope.$apply(); }
            if (data.state != 'userinput') {
                // if (data.formid && data.formid != '') {
                // }
                // if (data.header && data.header != '') {
                // }
                if (data.error && data.error != '') {
                    data.formid = null;
                    data.form = null;
                }
                else {
                    this.toastrObj.success(this.model.name +" Completed Successfully Please Check Iroboflow");
                    this.$location.path("/flows");
                    if (!this.$scope.$$phase) { this.$scope.$apply(); }
                }
            }

            if (data.formid && data.formid != '') {
                this.loadform(data.formid);
            }
        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));
            this.$location.path("/flows");

        }
    }

    addExtraForm(formElement) {

        if (formElement && formElement.forms) {
            //var formsLength = formElement.forms.length;
            var formItem = angular.copy(formElement.forms[0])
            formItem.form.map(FItem => {
                delete FItem.value;
            });
            formElement.forms.push(formItem);
        }

    }
    removeExtraForm(formobj: any, index) {
        if (index > 0)
            //delete formobj.forms[index];
            formobj.forms.splice(index, 1);
    }

}

class workflowsCtrl extends baseEntitiesCtrl {
    static $inject: Array<string> = ['$scope', '$location', '$compile', 'socketService', 'toastr'];
    showsubflows: boolean;
    newuser: string = '';
    newusermodel: any;
    actionsModel: any = {};
    userservice: socketServiceClass;
    genericServices: GenericServiceClass;
    wfInvoker: workflowinvokerCtrl;
    userentities: Array<any> = [];
    usercollection: any;
    toastrObj: any;
    userbasequery: any;
    limit: number = globalLoadData;
    seePermission: boolean = false;
    model: any;
    workflow: any;
    endpoint_id: string;
    instance_id: string;
    formid: string;
    focused: string = '';
    workflowServices: WorkflowsServiceClass;
    constructor(
        public $scope,
        public $location,
        public $compile,
        public socketService: socketServiceFactory,
        public toastr,
        public outer

    ) {
        super($scope, $location, $compile, socketService);
        this.collection = 'workflows';
        var user: any = this.socketService.user;
        this.genericServices = new GenericServiceClass(this.service.socket, this.service.namespace)
        this.workflowServices = new WorkflowsServiceClass(this.socketService.namespace.socket, this.socketService.namespace)

        if (!user.roles.find(R => R._id == adminsRoleId) && !user.roles.find(R => R._id == workflowUserRoleId) && !user.roles.find(R => R._id == formsUserRoleId)) {
            this.$location.path("/")
            this.$scope.$apply();
        }
        if (user.roles.find(R => R._id == adminsRoleId) || user.roles.find(R => R._id == workflowUserRoleId)) {
            this.seePermission = true;
        }
        if (this.$location.absUrl().indexOf('/admin/') > -1) {
            this.basequery = "(_type eq 'endpoint')";
        } else {
            this.basequery = "(_type eq 'endpoint')";
        }
        this.basequery += " and isDeleted eq false";
        if (!this.showsubflows) {
            this.basequery += " and userform eq true";
        }

        this.socketService.onSignedin(() => {
            // this.loaddata();
            this.getWorkflowsPagedData();
            this.userbasequery = { $or: [{ _type: 'user' }, { _type: 'role' }] }
        });
        this.userentities = []
        this.usercollection = 'users';
        this.userservice = socketService.namespace;
        this.toastrObj = toastr;
    }

    goback() {
        this.$location.path("/flows");
    }

    isDisabled(param) {
        if (!param.readonly || param.readonly == '') {
            return false;
        }
        return param.readonly;
    }

    processform() {
        if (!this.model.payload) { this.model.payload = {}; }
        var keys = Object.keys(this.model.payload);

        var firstInput: any = null;
        // save as result object, for use in workflow
        keys.forEach(name => {
            var target = this.model.form.find((ele) => { return ele.name == name; })
            if (target) {
                target.value = this.model.payload[name];
                if (!firstInput) { firstInput = name; }
            }
        });
        if (!firstInput && this.model.form) {
            this.model.form.forEach(element => {
                if (!firstInput) { firstInput = element.name; }
            });
        }
        if (this.model.defaults) {
            this.model.form.forEach(element => {
                if (this.model.defaults[element.name]) {
                    element.options = [];
                    // element.options = this.model.defaults[element.name];
                    // element.options.forEach(item => {
                    //     if(!item.id) {
                    //         if(item._id) { item.id = item._id; }
                    //     }
                    // });
                    var thedefault = this.model.defaults[element.name];
                    if (Array.isArray(thedefault)) {
                        this.model.defaults[element.name].forEach(item => {
                            if (item.id) {
                                element.options.push({ id: item.id, name: item.name })
                            } else if (item._id) {
                                element.options.push({ id: item._id, name: item.name })
                            }
                        });
                    } else {
                        element.value = thedefault;
                    }
                }
            });
        }

        // ensure elements are visible
        if (!this.$scope.$$phase) { this.$scope.$apply(); }
        // now set focused element
        if (this.focused != '') {
            this.focuson = this.focused;
        } else if (firstInput) {
            this.focuson = firstInput;
        } else {
            this.focuson = 'backbutton';
        }
        if (!this.$scope.$$phase) { this.$scope.$apply(); }
    }
    async loadform(id) {
        if (this.formid) {
            if (this.model.form && id == this.formid) {
                this.processform();
                return;
            }
        }
        if (id == '' && this.model.form) {
            this.model.form = [];
            this.processform();
            return;
        }
        this.loading = true;
        var query = "_id eq '" + id + "'";
        try {
            var items = await this.genericServices.get(query, 1, null, null, null, 'workflows');
            this.formid = id;
            this.loading = false;
            if (items.length == 0) {
                // handleError(this.$scope, { error: { message: 'No such form was found ' + id } });
                this.toastrObj.error(GetErrorMessage({ error: { message: 'No such form was found ' + id } }));
                return;
            }

            if (!this.model.form) {
                this.model.form = items[0].form;
            }
            this.processform();

        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));
        }
    }
    async invokeWorkflow(id) {
        this.loading = true;
        var query = "_id eq '" + id + "'";
        try {
            var items = await this.genericServices.get(query, 1, null, null, null, 'workflows');
            if (items.length == 0) {
                this.loading = false;
                // handleError(this.$scope, { error: { message: 'No such instance found ' + id } });
                this.toastrObj.error(GetErrorMessage({ error: { message: 'No such instance found ' + id } }));
                return;
            }
            if (items[0]._type == 'endpoint' || items[0]._type == 'wff') {
                this.workflow = items[0];
                this.endpoint_id = items[0]._id;
                await this.submitworkflowinstance(null);
                this.toastrObj.success("Workflow Invoked Successfully Please Check Iroboflow");

            }
            else {
                this.instance_id = id;
                this.model = items[0];
                if (!this.model._parent) {
                    this.loading = false;
                    // handleError(this.$scope, { error: { message: 'instance is missing _parent property!' } });
                    this.toastrObj.error(GetErrorMessage({ error: { message: 'instance is missing _parent property!' } }));
                    return;
                }
                this.endpoint_id = this.model._parent;
                if (this.model.state == 'new') {
                    await this.submitworkflowinstance(this.model);
                    this.toastrObj.success("Workflow Invoked Successfully Please Check Iroboflow");
                    return;
                }
                var data = this.model;
                this.loadform(data.formid);
                this.loading = false;
                

            }

            if (!this.$scope.$$phase) { this.$scope.$apply(); }
            this.loading = false;

        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));
        }
    }

    async submitworkflowinstance(model) {
        var endpoint_id = this.endpoint_id;
        if (model) {
            model = JSON.parse(angular.toJson(model));
        }

        // save as result object, for use in workflow
        if (model && model.form) {
            if (!model.payload) { model.payload = {}; }
            model.form.forEach(element => {
                //condition for table 
                if (element.type == "form" || element.type == "table") {
                    model.payload[element.name] = [];
                    element.forms.forEach(FormItem => {
                        var formData = {};
                        FormItem.form.forEach(FormElement => {


                            if (FormElement.name) {
                                formData[FormElement.name] = FormElement.value;
                            } else {
                                formData[FormElement.label] = FormElement.value;
                            }
                        });

                        model.payload[element.name].push(formData);

                    })
                } else {
                    if (element.name) {
                        model.payload[element.name] = element.value;
                    } else {
                        model.payload[element.label] = element.value;
                    }
                }

            });
        }
        clearError(this.$scope);
        // if (this.model && this.model.state != 'userinput' && this.model.state != 'new') {
        //    // this.goback();
        //     if (!this.$scope.$$phase) { this.$scope.$apply(); }
        //     return;
        // }
        //var $focused = $();

        var $focused = angular.element(document.querySelector(':focus'));
        if ($focused.length == 1) {
            this.focused = $focused[0].id;
        }
        this.focuson = '';
        if (!this.$scope.$$phase) { this.$scope.$apply(); }
        this.loading = true;
        try {
            var data = await this.workflowServices.invoke(endpoint_id, model);
            this.loading = false;
            if (this.instance_id != data._id) {
                if (data.state == 'userinput' || data.state == 'pending') {
                    //  this.$location.path("/workflowinvoker/" + data._id);
                    if (!this.$scope.$$phase) { this.$scope.$apply(); } 
                    return;
                }
            }
            this.model = data;
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
            if (data.state != 'userinput') {
                if (data.formid && data.formid != '') {
                }
                else if (data.header && data.header != '') {
                }
                else if (data.error && data.error != '') {
                    data.formid = null;
                    data.form = null;
                }
                else {
                    //this.$location.path("/main");
                    if (!this.$scope.$$phase) { this.$scope.$apply(); }
                }
            }
            if (data.formid && data.formid != '') {
                this.loadform(data.formid);
            }
 

        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));
        }
    }

    addExtraForm(formElement) {

        if (formElement && formElement.forms) {
            //var formsLength = formElement.forms.length;
            var formItem = angular.copy(formElement.forms[0])
            formItem.form.map(FItem => {
                delete FItem.value;
            });
            formElement.forms.push(formItem);
        }

    }
    removeExtraForm(formobj: any, index) {
        if (index > 0)
            //delete formobj.forms[index];
            formobj.forms.splice(index, 1);
    }

    togglesubflows() {
        if (this.$location.absUrl().indexOf('/admin/') > -1) {
            this.basequery = "(_type eq 'endpoint')";
        } else {
            this.basequery = "(_type eq 'endpoint')";
        }
        this.basequery += ' and isDeleted eq false'
        if (this.showsubflows) {
            this.basequery += " and userform eq true";
        }
        this.entities = [];
        this.page = 1;
        this.loaddata();
    }

    //Permission Fucntions 
    _arrayBufferToBase64(array_buffer): string {
        var binary = '';
        var bytes = new Uint8Array(array_buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i])
        }
        return window.btoa(binary);
    }
    setBit(base64: string, bit: number) {
        bit--;
        var buf = this._base64ToArrayBuffer(base64);
        var view = new Uint8Array(buf);
        var octet = Math.floor(bit / 8);
        var currentValue = view[octet];
        var _bit = (bit % 8);
        var mask = Math.pow(2, _bit);
        var newValue = currentValue | mask;
        view[octet] = newValue;
        return this._arrayBufferToBase64(view);
    }
    unsetBit(base64: string, bit: number) {
        bit--;
        var buf = this._base64ToArrayBuffer(base64);
        var view = new Uint8Array(buf);
        var octet = Math.floor(bit / 8);
        var currentValue = view[octet];
        var _bit = (bit % 8);
        var mask = Math.pow(2, _bit);
        var newValue = currentValue &= ~mask;
        view[octet] = newValue;
        return this._arrayBufferToBase64(view);
    }
    toogleBit(a: any, bit: number) {
        if (this.isBitSet(a.rights, bit)) {
            a.rights = this.unsetBit(a.rights, bit);
        } else {
            a.rights = this.setBit(a.rights, bit);
        }
    }
    isBitSet(base64: string, bit: number): boolean {
        bit--;
        var buf = this._base64ToArrayBuffer(base64);
        var view = new Uint8Array(buf);
        var octet = Math.floor(bit / 8);
        var currentValue = view[octet];
        var _bit = (bit % 8);
        var mask = Math.pow(2, _bit);
        return (currentValue & mask) != 0;
    }
    _base64ToArrayBuffer(string_base64): ArrayBuffer {
        var binary_string = window.atob(string_base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            //var ascii = string_base64.charCodeAt(i);
            var ascii = binary_string.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes.buffer;
    }
    async validateuser() {

        if (this.newuser.length > 0) {
            var query = "user eq '" + this.newuser + "' or username eq '" + this.newuser + "' or name eq '" + this.newuser + "'";

            var items = await this.genericServices.get(query, 1, null, null, null, 'users');

            if (items.length == 1) {
                this.newusermodel = items[0];
                if (!this.$scope.$$phase) { this.$scope.$apply(); }
            }
        }
    }
    adduser() {
        var ace = {
            deny: false,
            _id: this.newusermodel._id,
            name: this.newusermodel.name,
            rights: "//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8="
        }


        var exist = false;
        for (var i = 0; i < this.actionsModel._acl.length; i++) {
            if (this.actionsModel._acl[i]._id === this.newusermodel._id) {
                exist = true;
            }
        }
        if (exist === false) {
            this.actionsModel._acl.push(ace);
        }

    }
    removeuser(_id) {
        for (var i = 0; i < this.actionsModel._acl.length; i++) {
            if (this.actionsModel._acl[i]._id == _id) {
                this.actionsModel._acl.splice(i, 1);
                //this.model._acl = this.model._acl.splice(index, 1);
            }
        }
    }
    async update() {
        await this.genericServices.put(this.actionsModel, 'workflows');
    }
    async loadusers() {
        clientInformation
        clearError(this.$scope);
        if (this.usercollection == 'workflows') {
            this.userservice = this.socketService.namespace;
        } else if (this.usercollection == 'users') {
            this.userservice = this.socketService.namespace;
        }
        //debugger;
        if (!this.genericServices.isConnected()) return;
        this.loading = true;
        //var t0 = performance.now();
        var query = this.userbasequery;
        if (this.searchstring !== '') {
            if (query != '') { query += " and "; }
            query += "contains(name,'" + this.searchstring + "')";
        }
        try {

            var items = await this.genericServices.get(query, this.limit, null, this.order.field, this.order.reverse, this.usercollection);
            this.loading = false;
            //if (odata.count) { this.inlineCount = odata.count; }
            this.userentities = [];
            items.forEach(member => {

                this.userentities.push({ name: member.name, id: member._id })

            });

            //le.log(this.userentities);
            this.scrollloading = (items.length > 0);
            this.loading = false;
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));
        }
    }
}

class formCtrl {
    static $inject: Array<string> = ['$scope', '$location', 'socketService', '$routeParams', '$timeout',
        '$compile', '$uibModal', 'toastr'];
    user: any;
    optionsList: any;
    selectedList: any;
    id: string;
    model: any;
    property: zenField;
    genericServices: GenericServiceClass;
    keys: string[];
    loading: boolean;
    users: any[];
    selected: string;
    toastrObj: any;
    columns: any;
    elements: Array<zenField> = [
        zenField.component('text field'),
        zenField.component('select field'),
        zenField.component('checkbox'),
        zenField.component('button'),
        zenField.component('multi checkbox'),
        zenField.component('preformatted text'),
        zenField.component('form'),
        zenField.component('table')
    ];

    form: any;
    constructor(
        public $scope,
        public $location,
        public socketService: socketServiceFactory,
        public $routeParams,
        public $timeout,
        public $compile,
        public $uibModal,
        public toastr
    ) {
        this.toastrObj = toastr;
        var user: any = this.socketService.user;
        this.genericServices = new GenericServiceClass(this.socketService.namespace.socket, this.socketService.namespace)

        if (!user.roles.find(R => R._id == adminsRoleId) && !user.roles.find(R => R._id == workflowUserRoleId) && !user.roles.find(R => R._id == noderedUserRoleId)) {
            this.$location.path("/")
            this.$scope.$apply();
        }
        this.id = this.$routeParams.id;
        this.socketService.onSignedin(() => {
            if (this.id) {
                this.loaddata(this.id);
            }
            else {
                this.model = {
                    _type: 'form',
                    form: []
                    // ,name: 'New form'
                };
            }
        });
    }
    getNumber(num) {
        return new Array(num);

    }


    addLocalization() {
        var modalInstance = this.$uibModal.open({
            windowTemplateUrl: 'templateWindow.html',
            templateUrl: 'myModalContent.html',
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            controllerAs: '$ctrl',
            controller: function ($http, $scope, $uibModalInstance) {
                var $ctrl = this;
                $ctrl.name = 'hi mom';
                $ctrl.items = ['item1', 'item2', 'item3'];
                $ctrl.selected = {
                    item: $ctrl.items[0]
                };
                $ctrl.ok = function () {
                    $uibModalInstance.close($ctrl.selected.item);
                };
                $ctrl.cancel = function () {
                    $uibModalInstance.dismiss('cancel');
                };
            },
            resolve: {
                items: function () {
                    return 'hi mom';
                },
            }
        });



        modalInstance.result.then((selectedItem) => {
            this.selected = selectedItem;
        }, function () {
        });
    }
    goback() {
        this.$location.path("/forms");
    }


    async loaddata(id) {
        this.loading = true;
        var query = "_id eq '" + this.id + "'";
        try {
            var items = await this.genericServices.get(query, 1, null, null, null, 'workflows');
            this.loading = false;
            if (items.length == 0) {
                // handleError(this.$scope, { error: { message: 'No such workflow instance was found ' + id } });
                this.toastrObj.error(GetErrorMessage({ error: { message: 'No such workflow instance was found ' + id } }));
                return;
            }
            this.model = items[0];
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));
        }
    }

    async update() {
        this.loading = true;
        try {

        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));
        }

        if (!this.model.name) {
            this.toastrObj.error(GetErrorMessage(new Error('Please add form name')));
            return;
        }
        if (!this.model.form || this.model.form.length <= 0) {
            this.toastrObj.error(GetErrorMessage(new Error('Please add elements to the form')));
            return;
        }


        if (this.model.form)
            await this.model.form.map((I) => {
                if (I.type == "form" || I.type == "table") {
                    if(I.forms[0]) delete I.forms[0]._acl ;
                    if(I.forms[0]) delete I.forms[0]._encrypt;
                    if(I.forms[0]) delete I.forms[0]._version;
                    if(I.forms[0]) delete I.forms[0]._createdby;
                    if(I.forms[0]) delete I.forms[0]._createdbyid;
                    if(I.forms[0]) delete I.forms[0]._created;
                    if(I.forms[0]) delete I.forms[0]._modifiedby;
                    if(I.forms[0]) delete I.forms[0]._modifiedbyid;
                    if(I.forms[0]) delete I.forms[0]._modified;
                }
            });



        if (this.model._id) {
            if (this.model.form)
                // remove options from form

                await this.genericServices.put(this.model, 'workflows');
            this.loading = false;

            this.$location.path("/forms");
            if (this.model.allowguests) {
            }
            else {

            }

            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } else {
            if (this.model.form.length == 0) {
                this.$location.path("/forms");
                return;

            }
            await this.genericServices.addForm(this.model, 'workflows');
            this.model._acl = [];
            if (this.model.allowguests) {
                var ace = {
                    deny: false,
                    _id: '5be41011ae5dd53d4b4b2db0',
                    name: 'guests',
                    rights: "4v////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8="
                }
                this.model._acl.push(ace);
            }
            this.loading = false;
            this.$location.path("/forms");
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        }
    }
    click(model) {
        this.property = model;
        if (!this.$scope.$$phase) { this.$scope.$apply(); }
    }

}

class formsCtrl extends baseEntitiesCtrl {
    static $inject: Array<string> = ['$scope', '$location', '$compile', 'socketService', 'toastr'];
    showcompleted: boolean = false;
    deletecounter: number = 0;
    toastrObj: any;
    constructor(
        public $scope,
        public $location,
        public $compile,
        public socketService: socketServiceFactory,
        public toastr
    ) {
        super($scope, $location, $compile, socketService);
        this.toastrObj = toastr;
        this.collection = 'workflows';
        var user: any = this.socketService.user;
        if (!user.roles.find(R => R._id == adminsRoleId) && !user.roles.find(R => R._id == workflowUserRoleId) && !user.roles.find(R => R._id == noderedUserRoleId)) {
            this.$location.path("/flows")
            this.$scope.$apply();
        }
        this.order = new orderby({ field: "_modified", reverse: true })
        this.socketService.onSignedin(() => {
            this.basequery = '_type eq \'form\'';
            if (this.showcompleted == false) {
                this.basequery += " and state ne 'disabled'";
            }
            // this.loaddata();
            this.getWorkflowsPagedData();
        });
    }
    adjustcompleted() {
        this.basequery = '_type eq \'form\'';
        if (this.showcompleted == false) {
            this.basequery += " and state ne 'disabled'";
        }
        this.page = 1;
        this.entities = [];
        this.loaddata();
    }

}

